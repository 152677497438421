body {
  margin: 0;
    font-family: 'Signika', 'Segoe UI', 'Roboto', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('../public/background.png'); /* Adjust the path as necessary */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    overflow: overlay; 
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

button,
input.button,
input,
textarea,
select {
  font-family: 'Signika';
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* Customize the scrollbar thumb */
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Customize the scrollbar track */
}


