/* SubmissionsPage.css */

.submissions-list-container {
    margin: 20px auto;
    width: 80%;
    max-width: 900px;
    text-align: center;
}

.submissions-list-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #eee;
    text-shadow: #000 2px 2px 2px;
}

.submissions-list {
    width: 100%;
    background-color: #111;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submissions-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    table-layout: fixed;
}

.submissions-table th,
.submissions-table td {
    padding: 15px 10px;
    text-align: center;
    border: 1px solid #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.submissions-table th:nth-child(1), .submissions-table td:nth-child(1) {
    width: 20%;
}

.submissions-table th:nth-child(2), .submissions-table td:nth-child(2) {
    width: 20%;
}

.submissions-table th:nth-child(3), .submissions-table td:nth-child(3) {
    width: 20%;
}

.submissions-table th:nth-child(4), .submissions-table td:nth-child(4) {
    width: 20%;
}

.submissions-table th:nth-child(5), .submissions-table td:nth-child(5) {
    width: 20%;
}

.submissions-table th {
    background-color: #222;
    text-shadow: #000 1px 1px 1px;
    font-size: 16px;
    font-weight: 600;
}

.submissions-table td {
    background-color: #000;
    transition: background-color 0.3s ease;
}

.submission-link-row:hover td {
    background-color: #444;
}

.submission-link {
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.pagination li {
    display: inline;
    margin: 0 5px;
}

.pagination li a {
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    background-color: #1c1c1c;
    border: 1px solid #444;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination li a:hover {
    background-color: #333;
}

.pagination .active a {
    background-color: #ddd;
    border-color: #333;
}
