.problem-form-container {
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #111;
    color: #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.problem-form-container h2,
.delete-problem-container h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}

.problem-form-container form,
.delete-problem-container form {
    display: flex;
    flex-direction: column;
}

.problem-form-container input,
.problem-form-container textarea,
.problem-form-container .button,
.delete-problem-container input,
.delete-problem-container .button {
    padding: 12px;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 10px 0;
    background-color: #000;
    color: #eee;
    font-size: 16px;
}

.problem-form-container input::placeholder,
.problem-form-container textarea::placeholder,
.delete-problem-container input::placeholder {
    color: #aaa;
}

.problem-form-container textarea {
    resize: vertical;
    height: 100px;
}

.problem-form-container .button,
.delete-problem-container .button {
    background-color: #000;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.problem-form-container .button:hover,
.delete-problem-container .button:hover {
    background-color: #eee;
    color: #000;
}

.problem-form-container .file-input-label {
    display: block;
    margin-top: 15px;
    color: #aaa;
}

.problem-form-container .file-input {
    border: none;
    padding: 5px;
    background-color: #111;
    color: #eee;
}


.delete-problem-container {
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #111;
    color: #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.problem-form-container textarea {
    resize: none;
    height: 100px;
    margin-bottom: 10px;
}

.test-case-container {
    margin-bottom: 20px;
}

.file-upload-container {
    display: flex;
    align-items: center;
    position: relative;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    margin-left: 8px;
}

.tooltip-icon {
    font-size: 16px;
    color: #eee;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    width: 300px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    margin-left: -150px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
