.problem-detail-container {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 80%;
    max-width: 1200px;
    background-color: #111;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden; /* Prevent overflow */
}

.problem-detail-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #eee;
    text-align: center;
}

.problem-buttons{
    padding: 15px 20px 5px 20px;
}

.problem-desc{
    text-align: left;
}

.problem-detail-content{
    flex: 1;
    padding: 20px 20px;
    background-color: #000;
    color: #ddd;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 10px;
    overflow: auto; /* Allow content to scroll */
}


.editor-container {
    flex: 1;
    padding: 20px 20px;
    color: #ddd;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 10px;
    overflow: auto; /* Allow content to scroll */
}

.resizable-container {
    display: flex;
    flex-grow: 1;
    overflow: hidden; /* Prevent overflow */
}

.resizable {
    flex: 1;
    overflow: auto; /* Allow content to scroll */
    padding: 0 10px;
}

.editor-container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.language-select,
.test-case-container,
.submit-buttons-container {
    margin: 20px 0;
}

.language-select select,
.test-case-container textarea {
    resize: none;
    padding: 5px;
    border: 1px solid #333;
    background-color: #000;
    color: #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding in width */
}

.description-button,
.submit-button,
.add-test,
.test-case button {
    background-color: #111;
    border: 1px solid #333;
    color: #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.description-button:hover,
.submit-button:hover,
.add-test:hover,
.test-case button:hover {
    background-color: #eee;
    color: #111;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.output-container {
    background-color: #000;
    border: 1px solid #333;
    color: #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}
 
.message-container p{
    margin: 0px 0px;
}

@media (max-width: 768px) {
    .resizable-container {
        flex-direction: column;
    }
    .resizable {
        min-width: 100%;
        max-width: 100%;
    }
}
