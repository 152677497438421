.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #343a40;
  font-family: Arial, sans-serif;
  text-align: center;
}

.not-found-message {
  font-size: 24px;
  font-weight: bold;
}