.forum-container {
    margin: 20px auto;
    width: 60%;
    max-width: 1200px;
    background-color: #111;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    color: #eee;
    box-sizing: border-box;
}

.forum-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #eee;
    text-align: center;
}

.messages-container {
    max-height: 60vh;
    overflow-y: auto;
    background-color: #000;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #333;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.message {
    background-color: #111;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.message-user {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}

.message-content {
    text-align: left;
    background-color: #222;
    padding: 10px;
    border-radius: 5px;
}

.message-input-container {
    background-color: #000;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #333;
    box-sizing: border-box;
}

.message-input-container textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    border: 1px solid #333;
    background-color: #111;
    color: #ddd;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.add-code-button,
.post-button {
    background-color: #111;
    border: 1px solid #333;
    color: #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
    padding: 10px;
}

.add-code-button:hover,
.post-button:hover {
    background-color: #eee;
    color: #111;
}
