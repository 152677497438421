/* Homepage.css */

.problem-list-container {
    margin: 20px auto;
    width: 80%;
    max-width: 900px;
    text-align: center;
}

.problem-list-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #eee;
    text-shadow: #000 2px 2px 2px;
}

.problem-list {
    width: 100%;
    background-color: #111; /* Background color for the container */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.problem-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #333; /* Slightly lighter background for the table */
    color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* Add this line to ensure fixed table layout */
}

.problem-table th,
.problem-table td {
    padding: 15px 10px;
    text-align: center;
    border: 1px solid #333; /* Light border color */
    overflow: hidden; /* Ensure content that exceeds the width is hidden */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
}

/* Set fixed widths for each column */
.problem-table th:nth-child(1), .problem-table td:nth-child(1) {
    width: 30; /* Adjust as needed */
}

.problem-table th:nth-child(2), .problem-table td:nth-child(2) {
    width: 20%; /* Adjust as needed */
}

.problem-table th:nth-child(3), .problem-table td:nth-child(3) {
    width: 15%; /* Adjust as needed */
}

.problem-table th:nth-child(4), .problem-table td:nth-child(4) {
    width: 15%; /* Adjust as needed */
}

.problem-table th:nth-child(5), .problem-table td:nth-child(5) {
    width: 20%; /* Adjust as needed */
}

.problem-table th {
    background-color: #222; /* Darker background for headers */
    text-shadow: #000 1px 1px 1px;
    font-size: 16px;
    font-weight: 600;
}

.problem-table td {
    background-color: #000;
    transition: background-color 0.3s ease;
}

.problem-link-row:hover td {
    background-color: #444;
}

.problem-link {
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.pagination li {
    display: inline;
    margin: 0 5px;
}

.pagination li a {
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    background-color: #1c1c1c;
    border: 1px solid #444;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination li a:hover {
    background-color: #333;
}

.pagination .active a {
    background-color: #1e90ff;
    border-color: #1e90ff;
}
